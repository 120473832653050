import React from 'react'
import Layout from "../../components/Layout"
import AccountLayout from "../../components/Account/AccountLayout"
import SvgIcon from "../../components/SvgCurve"
import Input from "../../components/Input"

export default function ProfileInformation() {
    return (
        <Layout>
            <AccountLayout>
                <div className="ProfileInformation">
                    <span className="ProfileInformation-title"><SvgIcon className="ProfileInformation-icon" icon="cardId" />Mis datos</span>
                    <span className="ProfileInformation-text">No dudes de editar cualquiera de tus datos que se muestran a continuación, de este modo tu cuenta de Laie estará actualizada.</span>
                    <div className="ProfileInformation-content">
                        <form>
                            <Input label="Nombre" value="Jose Luis" />
                            <Input label="Apellido" />
                            <Input label="Email" type="email" />
                            <Input label="Nif" />
                            <Input label="Teléfono" />
                            <button className="ProfileInformation-btn">Guardar</button>
                        </form>
                    </div>
                </div>
            </AccountLayout>
        </Layout>
    )
}
